import request from '@/utils/request'

// 海鲜卡列表
export const cardListAPI = (data) => {
  return request({
    url: '/cardList',
    method: 'POST',
    data
  })
}

// 详情
export const cardDetailsAPI = (data) => {
  return request({
    url: '/cardhtInfo',
    method: 'POST',
    data
  })
}

// 导入
export const ExcelImportAPI = (dataset) => {
  return request({
    url: '/excel_import',
    method: 'POST',
    data: {
      dataset
    }
  })
}

// 详情
export const detailAPI = (params) => {
  return request({
    url: '/carddetaillist',
    method: 'GET',
    params
  })
}

// 发货-填写单号
export const saveDeilverAPI = (data) => {
  return request({
    url: '/savedeilver',
    method: 'POST',
    data
  })
}

// 添加海鲜卡
export const addCardAPI = (data) => {
  return request({
    url: '/cardAdd',
    method: 'POST',
    data
  })
}

<template>
  <el-dialog
    v-model="addCardShow"
    @close="handleClose"
    title="添加海鲜卡"
    width="30%"
  >
    <el-form
      label-width="100px"
      :model="addForm"
      :rules="addFormRules"
      ref="hospitalFormRef"
    >
      <el-form-item label="卡号" prop="card_number">
        <el-input placeholder="请输入卡号" v-model="addForm.card_number" />
      </el-form-item>
      <el-form-item label="卡密" prop="card_password">
        <el-input placeholder="请输入卡密" v-model="addForm.card_password" />
      </el-form-item>
      <el-form-item label="产品名称" prop="proname">
        <el-input placeholder="请输入产品名称" v-model="addForm.proname" />
      </el-form-item>
      <el-form-item
      label="总发货次数"
      prop="card_times"
      :rules="[{type:'number', message:'年龄必须为数字值'}]"
      >
        <el-input placeholder="请输入总发货次数" v-model.number="addForm.card_times" />
      </el-form-item>
      <el-form-item label="到期时间" prop="expiration_date">
              <el-date-picker
                v-model="addForm.expiration_date"
                type="datetime"
                placeholder="请输入到期时间"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                :size="size"
                style="width: 500px"
              />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose"> 取消</el-button>
        <el-button type="primary" @click="onOkBtn"> 确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import { addCardAPI } from '@/api/card'
import { useVModel } from '@vueuse/core'
import { ElMessage } from 'element-plus'
/**
 *  v-model
 */
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },

  editorial: {
    type: Object
  },

  onListData: {
    type: Function
  }
})
defineEmits(['update:modelValue', 'editorial']) // 注册 v-model 事件
const addCardShow = useVModel(props) // 得到一个响应式的数据，可以直接修改

/**
 *  v-model
 */

watch(
  () => props.editorial,
  (val) => {
    addForm.value = val
  }
)

/**
 *  表单 S
 */
// 表单数据源
const addForm = ref({
  card_number: '',
  card_password: '',
  card_times: '',
  expiration_date: '',
  proname: ''
})

// 验证规则
const addFormRules = ref({
  card_number: [
    {
      required: true,
      trigger: 'blur',
      message: '卡号必填'
    }
  ],
  card_password: [
    {
      required: true,
      trigger: 'blur',
      message: '卡密必填'
    }
  ],
  proname: [
    {
      required: true,
      trigger: 'blur',
      message: '产品名称必填'
    }
  ],
  card_times: [
    {
      required: true,
      trigger: 'blur',
      message: '发货总数必填'
    }
  ],
  expiration_date: [
    {
      required: true,
      trigger: 'blur',
      message: '到期时间必填'
    }
  ]
})

// 取消
const handleClose = () => {
  addCardShow.value = false
  hospitalFormRef.value.resetFields()
  addForm.value = {}
  props.onListData()
}

// 确认
// 获得 form 实例
const hospitalFormRef = ref(null)
const onOkBtn = () => {
  hospitalFormRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      await addCardAPI(addForm.value)
      ElMessage.success('添加海鲜卡成功')
      props.onListData()
    } catch (error) {
      console.log(error)
    } finally {
      addCardShow.value = false
    }
  })
}

/**
 *  表单 E
 */
</script>

<style lang="scss" scoped></style>
